/* aileron-latin-100-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: url('./files/aileron-latin-100-italic.woff2') format('woff2'), url('./files/aileron-latin-100-italic.woff') format('woff');
  
}
/* aileron-latin-100-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url('./files/aileron-latin-100-normal.woff2') format('woff2'), url('./files/aileron-latin-100-normal.woff') format('woff');
  
}
/* aileron-latin-300-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/aileron-latin-300-italic.woff2') format('woff2'), url('./files/aileron-latin-300-italic.woff') format('woff');
  
}
/* aileron-latin-300-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/aileron-latin-300-normal.woff2') format('woff2'), url('./files/aileron-latin-300-normal.woff') format('woff');
  
}
/* aileron-latin-400-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/aileron-latin-400-italic.woff2') format('woff2'), url('./files/aileron-latin-400-italic.woff') format('woff');
  
}
/* aileron-latin-400-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./files/aileron-latin-400-normal.woff2') format('woff2'), url('./files/aileron-latin-400-normal.woff') format('woff');
  
}
/* aileron-latin-600-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url('./files/aileron-latin-600-italic.woff2') format('woff2'), url('./files/aileron-latin-600-italic.woff') format('woff');
  
}
/* aileron-latin-600-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('./files/aileron-latin-600-normal.woff2') format('woff2'), url('./files/aileron-latin-600-normal.woff') format('woff');
  
}
/* aileron-latin-700-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('./files/aileron-latin-700-italic.woff2') format('woff2'), url('./files/aileron-latin-700-italic.woff') format('woff');
  
}
/* aileron-latin-700-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./files/aileron-latin-700-normal.woff2') format('woff2'), url('./files/aileron-latin-700-normal.woff') format('woff');
  
}
/* aileron-latin-800-italic*/
@font-face {
  font-family: 'Aileron';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url('./files/aileron-latin-800-italic.woff2') format('woff2'), url('./files/aileron-latin-800-italic.woff') format('woff');
  
}
/* aileron-latin-800-normal*/
@font-face {
  font-family: 'Aileron';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('./files/aileron-latin-800-normal.woff2') format('woff2'), url('./files/aileron-latin-800-normal.woff') format('woff');
  
}

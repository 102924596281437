/* roboto-latin-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url('./files/roboto-latin-100-normal.woff2') format('woff2'), url('./files/roboto-latin-100-normal.woff') format('woff');
  
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-latin-300-normal.woff2') format('woff2'), url('./files/roboto-latin-300-normal.woff') format('woff');
  
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./files/roboto-latin-400-normal.woff2') format('woff2'), url('./files/roboto-latin-400-normal.woff') format('woff');
  
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./files/roboto-latin-500-normal.woff2') format('woff2'), url('./files/roboto-latin-500-normal.woff') format('woff');
  
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./files/roboto-latin-700-normal.woff2') format('woff2'), url('./files/roboto-latin-700-normal.woff') format('woff');
  
}
/* roboto-latin-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('./files/roboto-latin-900-normal.woff2') format('woff2'), url('./files/roboto-latin-900-normal.woff') format('woff');
  
}

@media (prefers-color-scheme: dark) {
  /* TODO */
}
body {
  --bg: url("/doc/dojo.jpg");
  margin: 0;
  box-sizing: border-box;
  font-family: "Aileron", sans-serif;
  background-image: var(--bg);
  background-size: cover;
  background-position: center center;
}
body * { box-sizing: inherit; }

#app {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
}

#app header { }
#app main { flex-grow: 1; }
#app main aside {
  background-color: lightgray;
}
@supports (backdrop-filter: none) {
  #app main aside {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
}
#app footer { }

header {
  background-color: forestgreen;
}
header a {
  color: khaki;
  text-shadow: 0px 1px .1em darkgreen;
  transition: transform ease .3s, text-shadow ease .3s;
}
header a[href]:hover {
  text-shadow: 0px 2px .2em darkgreen;
  transform: scale(1.3);
}
header a[href]:active {
  transform: scale(.8);
}
header a:not([href]) {
  color: darkgreen !important;
}

article {
  background-color: snow;
  padding: 0 1.5rem;
}
article:empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
article:empty::before {
  content: 'Falta el contenido'
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Alice", serif;
}
h1 {
  font-size: 1.7em;
  /* color: darkkhaki; */
}
h2 {
  font-size: 1.4em;
}
/* see https://css-tricks.com/barebones-css-for-fluid-images/ */
img {
  max-width: 100%;
  line-height: 100%;
  text-align: center;
}
img[width] { width: auto; }
img[width][height] { height: auto; }
blockquote {
  font-family: "Aileron", sans-serif;
  font-style: italic;
  font-size: 1em;
}
ul {
  list-style-type: cjk-earthly-branch;
  margin: .1rem 0;
  padding-left: 2.5rem;
}
li::marker {
  display: inline-flex;
}
ul ul {
  list-style-type: ethiopic-numeric;
  padding-left: 1.15rem;
}
ul ul ul {
  list-style-type: none;
  padding-left: 0;
}

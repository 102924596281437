body {
  --bg: url("dojo.9b3a0bb5.jpg");
  box-sizing: border-box;
  background-image: var(--bg);
  background-position: center;
  background-size: cover;
  margin: 0;
  font-family: Aileron, sans-serif;
}

body * {
  box-sizing: inherit;
}

#app {
  height: 100vh;
  flex-flow: column;
  display: flex;
  overflow: auto;
}

#app main {
  flex-grow: 1;
}

#app main aside {
  background-color: #d3d3d3;
}

@supports (backdrop-filter: none) {
  #app main aside {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #ffffff80;
  }
}

header {
  background-color: #228b22;
}

header a {
  color: khaki;
  text-shadow: 0 1px .1em #006400;
  transition: transform .3s, text-shadow .3s;
}

header a[href]:hover {
  text-shadow: 0 2px .2em #006400;
  transform: scale(1.3);
}

header a[href]:active {
  transform: scale(.8);
}

header a:not([href]) {
  color: #006400 !important;
}

article {
  background-color: snow;
  padding: 0 1.5rem;
}

article:empty {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

article:empty:before {
  content: "Falta el contenido";
}

h1, h2, h3, h4, h5, h6 {
  font-family: Alice, serif;
}

h1 {
  font-size: 1.7em;
}

h2 {
  font-size: 1.4em;
}

img {
  max-width: 100%;
  text-align: center;
  line-height: 100%;
}

img[width] {
  width: auto;
}

img[width][height] {
  height: auto;
}

blockquote {
  font-family: Aileron, sans-serif;
  font-size: 1em;
  font-style: italic;
}

ul {
  margin: .1rem 0;
  padding-left: 2.5rem;
  list-style-type: cjk-earthly-branch;
}

li::marker {
  display: inline-flex;
}

ul ul {
  padding-left: 1.15rem;
  list-style-type: ethiopic-numeric;
}

ul ul ul {
  padding-left: 0;
  list-style-type: none;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-latin-100-normal.b393c3f0.woff2") format("woff2"), url("roboto-latin-100-normal.da82bbb9.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-latin-300-normal.e1075c83.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-latin-400-normal.32450484.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-latin-500-normal.bbb6d123.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-latin-700-normal.df41cf3b.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-latin-900-normal.5750b1bb.woff2") format("woff2"), url("roboto-latin-900-normal.42bebf18.woff") format("woff");
}

@font-face {
  font-family: Alice;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("alice-latin-400-normal.809bbf5b.woff2") format("woff2"), url("alice-latin-400-normal.8ffad5b0.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: url("aileron-latin-100-italic.5be386ac.woff2") format("woff2"), url("aileron-latin-100-italic.53561b97.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("aileron-latin-100-normal.ac424833.woff2") format("woff2"), url("aileron-latin-100-normal.11a376c2.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("aileron-latin-300-italic.cbf2c268.woff2") format("woff2"), url("aileron-latin-300-italic.6dda4296.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("aileron-latin-300-normal.9184b6f0.woff2") format("woff2"), url("aileron-latin-300-normal.f9e88a82.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("aileron-latin-400-italic.61c80a84.woff2") format("woff2"), url("aileron-latin-400-italic.c85cbf00.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("aileron-latin-400-normal.71748e70.woff2") format("woff2"), url("aileron-latin-400-normal.53f93299.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("aileron-latin-600-italic.0dfc250e.woff2") format("woff2"), url("aileron-latin-600-italic.ee416b80.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("aileron-latin-600-normal.acef13ed.woff2") format("woff2"), url("aileron-latin-600-normal.2b754e9a.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("aileron-latin-700-italic.11e44043.woff2") format("woff2"), url("aileron-latin-700-italic.a2f4e464.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("aileron-latin-700-normal.2155cced.woff2") format("woff2"), url("aileron-latin-700-normal.909a761a.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("aileron-latin-800-italic.cde620ed.woff2") format("woff2"), url("aileron-latin-800-italic.aa6b8506.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("aileron-latin-800-normal.6b35a683.woff2") format("woff2"), url("aileron-latin-800-normal.e10ee17d.woff") format("woff");
}

@font-face {
  font-family: Aileron;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("aileron-latin-400-italic.61c80a84.woff2") format("woff2"), url("aileron-latin-400-italic.c85cbf00.woff") format("woff");
}

/*# sourceMappingURL=index.431731aa.css.map */
